$accent-color-dark: #2b2b2b;
$accent-color-darker: #1c1c1c;

$bg-white: #f7f7f7;

//Breakpoints
$brk-sml: 48em; // 768px
$brk-med: 64em; //1024px
$brk-lrg: 85.375em; //1366px
$brk-xl: 120em; // 1920px
$brk-xxl: 160em; //2560px

//Media queries
$mq-small: "(min-width: #{$brk-sml})";
$mq-medium: "(min-width: #{$brk-med})";
$mq-large: "(min-width: #{$brk-lrg})";
$mq-xlarge: "(min-width: #{$brk-xl})";
$mq-xxlarge: "(min-width: #{$brk-xxl})";

//Font Size and Line Height
$base-font-size: 1em;
$base-line-height: 1.5;

//Scale Margins
.deck {
    margin: 0;
  
    @media #{$mq-medium} {
      margin: 0 6%;
    }
  
    @media #{$mq-large} {
      margin: 0 10%;
    }
  
    @media #{$mq-xlarge} {
      margin: 0 14%;
    }
  }

*{
    box-sizing: border-box;
    margin: 0;
}

body {
    background-color: #{$bg-white};
    font-size: $base-font-size;
    //Scale Font Size
    @media #{$mq-medium} {
        font-size: $base-font-size*1.2;
        line-height: $base-line-height*1.2;
      }
    
      @media #{$mq-large} {
        font-size: $base-font-size*1.3;
      }
    
      @media #{$mq-xlarge} {
        font-size: $base-font-size*1.4;
      }
    
      @media #{$mq-xxlarge} {
        font-size: $base-font-size*1.6;
      }
}

@import './sidebar';
@import './cards';
@import './footer';
@import './contact';
@import './brands';