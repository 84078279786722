.deck {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.card-container {
    margin: 0 20px;
    box-shadow: 5px 5px 5px rgb(100, 100, 100, 0.4);

    @media #{$mq-small} {
        display: grid;
        grid-template-columns: 250px 1fr;
    }

    @media #{$mq-medium} {
        display: grid;
        grid-template-columns: 300px 1fr;
    }

    @media #{$mq-large} {
        display: grid;
        grid-template-columns: 400px 1fr;
    }
}

.card-image-wrapper {
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    object-fit: fill;

    img {
        max-width: 100%;
    }
}

.card-hero {
    position: relative;
}

.card-heading-overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background-color: black;
    padding: 10px;
    border-radius: 5px;
}

.card-text-box {
    background-color: white;
    padding: 25px;
    color: #{$accent-color-dark};
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-brands {
    flex-direction: column;
}