.contact-form-wrapper {
    max-width: 100%;
}

.contact-us-form {
    // background-color: green;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 10px;
    position: relative;

    @media #{$mq-small} {
        grid-template-columns: 180px 300px;
        grid-template-rows: repeat(5, 1fr);
        grid-template-areas: 
        "info" "message";
    }

    @media #{$mq-medium} {
        grid-template-columns: 180px 300px;
        grid-template-rows: repeat(5, 1fr);
        grid-template-areas: 
        "info" "message";
    }

    @media #{$mq-large} {
        grid-template-columns: 200px 400px;
        grid-template-rows: repeat(5, 1fr);
        grid-template-areas: 
        "info" "message";
    }

    label {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.contact-input {
    padding: 5px;
    border: 2px solid black;
    border-radius: 4px;
    outline: none;
}

.contact-input:invalid {
    border-color: red;
}

.contact-input-personal-info {
    @media #{$mq-small} {
        grid-column-start: 1; 
    }
}

.contact-input-message {
    @media #{$mq-small} {
        width: 100%;
        border: 2px solid green;

        grid-column-start: 2;
        grid-row-start: 1;
        grid-row-end: 5;
    }
}

.contact-input-submit {
    width: 150px;
    background-color: green;
    color: white;
    border-style: none;
    padding: 15px;
    border-radius: 10px;

    @media #{$mq-medium} {
        grid-column-start: 1;
    }
}