.home-brands-container {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;

    @media #{$mq-small} {
        grid-template-columns: repeat(7, 1fr); 
    }

    @media #{$mq-medium} {
        grid-template-columns: repeat(6, 1fr); 
    }
}

.home-brand-image {
    width: 50px;

    @media #{$mq-medium} {
        width: 70px;
    }
}