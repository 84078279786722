.header-container {
    z-index: 1;
    display: flex;
    background-color: #{$accent-color-dark};
    height: 70px;
    padding: 0 15px;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 10px #{$accent-color-darker};
}

.fixed {
    position: fixed;
    top: 0;
    width: 100%;
}

.header-logo-container {
    width: 50px;
}

.header-logo {
    max-width: 100%;
}

.header-phone {
    color: green;

    a {
        text-decoration: none;
        color: green;

        :hover {
            color: lightgreen;
        }
    }
}